import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { DEFAULT_DATE_FORMAT } from '../../data/site-data';
import { formatReadingTime } from '../../utils';

function BlogPostMeta({ date, author, tags, seo, className = 'text-grey mb-5' }) {
  const readingTime = formatReadingTime(seo?.readingTime);

  return (
    <div
      className={`blog-meta-wrapper d-flex justify-content-start align-items-center ${className}`}>
      {date && (
        <p className="text-small text-uppercase blog-meta">
          <i className="far fa-calendar mr-3" />
          <strong>{moment(date).format(DEFAULT_DATE_FORMAT)}</strong>
        </p>
      )}
      {readingTime && (
        <p className="text-small ml-5 blog-meta">
          <i className="far fa-clock mr-3" />
          <strong>{readingTime}</strong>
        </p>
      )}
      {author?.node?.name && (
        <Link
          to="/blog/author/adrian/"
          className="text-small ml-5 blog-meta text-grey no-hover-link d-flex align-items-center">
          <StaticImage
            className="mr-3 photo-rounded"
            src="../../assets/img/pandev-photo.jpg"
            width={32}
            height={32}
            alt="Adrian Pandev Photo"
          />
          <strong>{author.node.name}</strong>
        </Link>
      )}
      {tags?.length > 0 && (
        <p className="text-small ml-5 blog-meta">
          <i className="far fa-tag mr-3" />
          <strong>{tags.nodes.map((node) => node.name).join(', ')}</strong>
        </p>
      )}
    </div>
  );
}

export default BlogPostMeta;
